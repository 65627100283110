import React from 'react'
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Typography } from '@material-ui/core';
import { Link } from 'gatsby';

interface PropTypes {
    className?: string;
}

const Footer = (props: PropTypes) => {
    const { className } = props;

    const styles = css(`
        padding: 5%;
    `);

    return (
        <div css={styles} className={className}>
            <Typography variant="body1">
                &copy;{' '}
                <Link
                to="/"
                >
                    GC Technology & Mobility Solutions
                </Link>
                . {new Date().getFullYear()}
            </Typography>
            <Typography variant="caption">
                Created by <a href="https://swizzdigital.com" rel="noopener" target="_blank">Swizz Digital</a>
            </Typography>
        </div>
    )
}

export default Footer