import React, { useState, useEffect } from "react"
import { Card, Button } from '@material-ui/core'
import { flexColCenterAll } from '../common/format-styles';

/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { navigate } from 'gatsby';
import { getAuth } from '../services/initFirebase';
import { AppUser } from '../data/app-user-model';
import { getUser } from '../services/user-service';
import DashMain from '../dashboard/main/dash-main';
import ResponsiveView from '../responsive/view';
import { headerHeight } from '../common/site-variables';
import { Console } from "console";
import AuthContext from "../data/auth-context";

interface PropTypes {
}

const Admin = (props: PropTypes) => {

  const [user, setUser] = useState<firebase.User>(getAuth() ? getAuth().currentUser : null);
  const authState = React.useContext(AuthContext);

  const styles = css(`
    width: 100%;
    min-height: calc(100vh - ${headerHeight});

    .noAuthMsg {
      width: 70%;
      margin: 10% 0;
      padding: 5%;
    }
  `);

  return (
    <ResponsiveView render={isMobile => (
      <DashMain isMobile={isMobile}>
        <div css={[styles, flexColCenterAll]}>
          {user ? (
            authState.dbUser?.role === 'admin' ?
              <div>Hello</div>
            : <h1>No admin privalage</h1>
          ) : (
            <Card css={flexColCenterAll} className="noAuthMsg">
              <h1>Please login first</h1>
              <Button variant="contained" onClick={() => navigate('/signin')}>Sign in / Sign up</Button>
            </Card>
          )}
        </div>
      </DashMain>
    )} />
  )
}

export default Admin
