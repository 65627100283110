import React, { ReactNode } from 'react'
/** @jsx jsx */
import { jsx, css } from '@emotion/core'

import DashboardIcon from '@material-ui/icons/Dashboard'
import PeopleIcon from '@material-ui/icons/People'
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket'
import { Drawer, Divider } from '@material-ui/core'
import Nav from './components/nav'
import Profile from './components/profile'
import { headerHeight, mobiHeaderHeight } from '../../../../common/site-variables'
import { AppUser } from '../../../../data/app-user-model'
import CategoryIcon from '@material-ui/icons/Category' 

export interface DashPages {
    title: string;
    href: string;
    icon: ReactNode;
}

interface PropTypes {
    isMobile: boolean;
    open: boolean;
    variant: 'permanent' | 'persistent' | 'temporary';
    onClose: () => void;
    className?: string;
    dbUser: AppUser;    
}

const pages = [
    // {
    //   title: 'Dashboard',
    //   href: '',
    //   icon: <DashboardIcon />
    // },
    // {
    //   title: 'Users',
    //   href: '',
    //   icon: <PeopleIcon />
    // },
    {
      title: 'Products',
      href: '/app/admin_products',
      icon: <ShoppingBasketIcon />
    },
    {
        title: 'Categories',
        href: '/app/admin_categories',
        icon: <CategoryIcon />
      },
]

const Sidebar = (props: PropTypes) => {
    const { isMobile, open, variant, onClose, className, dbUser } = props;

    const styles = css(`
        height: 100%;

        .MuiDrawer-paper {
            top: ${isMobile ? 0 : headerHeight};
            min-height: 100%;
            z-index: ${isMobile ? 2000 : 1000};
        }
        
        .root {
            width: 240px;
            height: calc(100% - ${isMobile ? mobiHeaderHeight : headerHeight});

            background-color: white;
            display: flex;
            flex-direction: column;
            padding: 6% 3%;
        }

        .divider {
            margin: 3% 0;
        }

        .nav {
            margin-bottom: 3%;
        }
    `);

    return (
        <Drawer
            anchor="left"
            css={styles}
            className={className}
            onClose={onClose}
            open={open}
            variant={variant}
        >
            <div className="root">
                { dbUser && <Profile dbUser={dbUser} />}
                <Divider className="divider" />
                <Nav className="nav" pages={pages} />
            </div>
        </Drawer>
    )
}

export default Sidebar