import React from 'react'
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { List, ListItem, Button } from '@material-ui/core';
import { DashPages } from '../side-bar';

interface PropTypes {
    pages: DashPages[];
    className?: string;
}

const Nav = (props: PropTypes) => {
    const { pages, className } = props;

    const styles = css(`
        .item {
            display: flex;
            padding-top: 0;
            padding-bottom: 0;
        }

        .button {
            color: black;
            padding: 10px 8px;
            justify-content: flex-start;
            text-transform: none;
            letter-spacing: 0;
            width: 100%;
            font-weight: medium;

            .icon {
                color: black;
                width: 24px;
                height: 24px;
                display: flex;
                align-items: center;
                margin-right: 2%;
            }
        }

        .active {
            color: red;

            .icon {
                color: red;
            }
        }
    `);

    return (
        <List css={styles} className={className}>
            {pages.map(page => (
                <ListItem
                    className="item"
                    disableGutters
                    key={page.title}
                >
                    <Button
                        className={`button`}
                        component="a"
                        href={page.href}
                    >
                        <div className="icon">{page.icon}</div>
                        {page.title}
                    </Button>
                </ListItem>
            ))}
        </List>
    )
}

export default Nav