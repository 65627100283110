import React, { ReactNode, useEffect, useState } from 'react'
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import Topbar from './components/top-bar';
import Sidebar from './components/sidebar/side-bar';
import ResponsiveView from '../../responsive/view';
import Footer from './components/sidebar/components/footer';
import { mobiHeaderHeight, headerHeight } from '../../common/site-variables';
import { StylesProvider, useMediaQuery } from '@material-ui/core';
import Header from '../../components/header';
import { AppUser } from '../../data/app-user-model';
import AuthContext from '../../data/auth-context';

interface PropTypes {
    children: ReactNode;
}

const DashMain = (props: PropTypes) => {
    const { children } = props;
    const isMobile = useMediaQuery('(max-width: 768px)');
    const authState = React.useContext(AuthContext);


    const styles = css(`
        padding-top: ${isMobile ? mobiHeaderHeight : headerHeight};
        height: 100%;
          
        .content {
            height: 100%;
        }
    `);

    const shiftContent = css(`
        padding-left: 240px;
    `);

    const [openSidebar, setOpenSidebar] = React.useState(!isMobile);

    const handleSidebarOpen = () => {
        setOpenSidebar(true);
    };

    const handleSidebarClose = () => {
        setOpenSidebar(false);
    };

    const shouldOpenSidebar = !isMobile;

    return (
        <StylesProvider injectFirst>
            <section css={[styles, !isMobile && shiftContent]} >
                <ResponsiveView render={isMobile => (
                    <Header isMobile={isMobile} dashboard onSidebarOpen={handleSidebarOpen} />
                )} />

                <ResponsiveView render={isMobile => (
                    <Sidebar 
                        dbUser={authState.dbUser}
                        onClose={handleSidebarClose}
                        open={openSidebar}
                        variant={!isMobile ? 'persistent' : 'temporary'}
                        isMobile={isMobile}
                    />
                )} />

                <main className="content">
                    {children}
                    <Footer />
                </main>
            </section>
        </StylesProvider>
    )
}

export default DashMain