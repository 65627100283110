import React from 'react'
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Avatar, Typography } from '@material-ui/core';
import { Link } from 'gatsby';
import { AppUser } from '../../../../../data/app-user-model';
import { toCamelCase } from '../../../../../helper/to-camel-case';
import { getFileURL } from '../../../../../services/product-service';

interface PropTypes {
    dbUser: AppUser;
}

const Profile = (props: PropTypes) => {
    const { dbUser } = props;

    const styles = css(`
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: fit-content;

        .avatar {
            width: 100px;
            height: 100px;
            img {
                margin: auto;
            }
        }

        .name {
            margin-top: 2%;
        }
    `);
    

    return (
        <div css={styles}>
            <Avatar
                alt="Person"
                className="avatar"
                src={getFileURL(dbUser.avatar, dbUser.token)}
            />
            <Typography
                className="name"
                variant="h4"
            >
                {toCamelCase(`${dbUser?.first_name.charAt(0)} ${dbUser?.last_name}`)}
            </Typography>
            <Typography variant="body2">{dbUser.title}</Typography>
        </div>
    )
}

export default Profile